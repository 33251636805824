$font-family: "Poppins", sans-serif;
$primary-color: #fff;
$main-color: #9e854d;
$main-color-light: #9e854d61;
$secondary-color: #dcba6c;
$primary-background-color: #000;
$gray-black-color: #333;
$gray-black-shadow-color: #222;
$red-color: #c31432;
$green-color: #008000;
$dark-gray-color: #4d4d4d;
$light-gray-color: #958f8f;
$bright-blue-color: #3498db;
$mine-shaft-color: #393939;
$cold-gray-color: #111111;
$Gray70-color: #b3b3b3;
$davys-grey-color: #555;
$charleston-green-color: #282828;
$shade-of-gray-color: #777;
$gray-black-shadow-color-sidebar: #222222;
$primary-black: #000000;
$gray: #808080;
$dark-gray-tone-color: #3b3b3b;
$dark-red: #191919;
$medium-light-shade-of-gray: #bbb;
$transparent-background: transparent;
$cursor-pointer: pointer;
$position-relative: relative;
$position-absolute: absolute;
