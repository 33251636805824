@import "./variable";

//reset css

*,
*:before,
*:after {
  box-sizing: border-box;
}
a,
a:hover {
  text-decoration: none;
}
html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer,
header,
nav,
section,
main {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  // -webkit-appearance: none;
  border-radius: 0;
}

body {
  background-color: $primary-background-color;
  color: $primary-color;
  font-family: $font-family;
}

// common css

.button {
  background-color: #f37335;
  border: none;
  color: white;
  padding: 10px 30px;
  font-size: 16px;
  cursor: $cursor-pointer;
}

.cast {
  background-color: $red-color;
  border: none;
  color: white;
  padding: 10px 30px;
  font-size: 16px;
  cursor: $cursor-pointer;
  opacity: 1;
}

.cast.button-round {
  border-radius: 6px;
}

.button.button-round {
  border-radius: 50px;
}
.bg-dark-gray {
  background-color: #333;
}
.button.button-grey {
  background-color: #383838;
}
.button.button-yellow {
  background-color: $main-color;
}
.button.button-grayish-blue {
  background-color: #35322a;
}
.button.button-danger {
  background-color: $red-color;
}
.radius-8px {
  border-radius: 8px;
}
.radius-6px {
  border-radius: 6px;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.margin8 {
  margin: 8px 0;
}
.ml-0 {
  margin-left: 0px;
}
.ml-20 {
  margin-left: 20px;
}
.mt-0 {
  margin-top: 0px;
}
.mt-20 {
  margin-top: 20px;
}

.t-35 {
  top: 35px !important;
}
.b-5 {
  bottom: -5px;
  top: unset !important;
}

.b-5 {
  bottom: -5px;
  top: unset !important;
}


.mr-12 {
  margin-right: 12px !important;
}

.mr-0 {
  margin-right: 0px !important;
}
.mr-95 {
  margin-right: 95px;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pt-0 {
  padding-top: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.pt-3 {
  padding-top: 3px;
}
.pt-10 {
  padding-top: 10px;
}
.pl-15 {
  padding-left: 15px !important;
}
.pt-15 {
  padding-top: 15px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-30 {
  padding-right: 30px !important;
}
.pt-15 {
  padding-top: 15px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-20 {
  padding-bottom: 20px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-4 {
  margin-left: 4px;
}

.mt-5 {
  margin-top: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.mb-5 {
  margin-bottom: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-80 {
  margin-top: 80px;
}
.mr-10 {
  margin-right: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.mt-15 {
  margin-top: 15px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-4 {
  margin-right: 4px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-65 {
  margin-right: 65px;
}
.mr-80 {
  margin-right: 80px;
}
.mb-15 {
  margin-bottom: 15px;
}
.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-space-evenly {
  justify-content: space-evenly;
}
.justify-content-end {
  justify-content: end;
}
.flex-1 {
  flex: 1;
}
.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50%;
}
.w-49 {
  width: 49%;
}
.w-32 {
  width: 32% !important;
}
.h-100 {
  height: 100%;
}
.small {
  font-size: 12px;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-dark {
  color: $dark-gray-color;
}
.text-light {
  color: $Gray70-color;
}
.text-gold {
  color: $main-color;
}
.text-color-white {
  color: #ffffff;
}
.text-half-dark {
  color: #808080;
}
.text-extra-light {
  color: #b4cccc;
}
.text-danger {
  color: $red-color;
}
.toast-success {
  color: #12998e;
}
.toster-icon {
  font-size: 24px !important;
}
.text-success {
  color: $green-color;
}
.text-yellow {
  color: #e0cc18;
}

.text-yellow-shadow-cast {
  color: #dcba6c !important;
}
.text-white {
  color: $primary-color;
}
.text-yellow-shadow {
  color: $secondary-color;
}
.bg-black {
  background: $primary-background-color !important;
}

.gray-color-70 {
  color: $Gray70-color;
}
.fs-5 {
  font-size: 5px;
}
.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}
.fs-20 {
  font-size: 20px;
}
.fw-800 {
  font-weight: 900;
}
.border-left {
  border-left: 1px solid $Gray70-color;
}
.border-top {
  border-top: 1px solid $Gray70-color;
}
.border-bottom {
  border-bottom: 1px solid $Gray70-color;
}
.border-right {
  border-right: 1px solid $Gray70-color;
}
.mb-40 {
  margin-bottom: 40px;
}
.font-medium {
  font-weight: 600;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.opacity-zero{
  opacity: 0;
}
.zIndex-minus{
  z-index: -999 !important;
}

.hidden {
  visibility: hidden;
}
.pointer-event {
  cursor: $cursor-pointer;
}
.avoid-clicks {
  pointer-events: none;
}

.pointer-none {
  cursor: not-allowed;
}
.pointer-event-disabled {
  cursor: not-allowed;
}

.pointer-event {
  cursor: $cursor-pointer;
}
/* Loader */
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  top: 45%;
  color: #ffffff;
  font-size: 5px;
  text-indent: -99999em;
  margin: 55px auto;
  position: $position-relative;
  width: 10em;
  height: 10em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: $position-absolute;
  content: "";
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #dcba6c;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #dcba6c;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.main-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  .main-content {
    flex: 1;
  }
  .loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.fa-1x {
  font-size: 1em;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-xs {
  font-size: .75em;
}

.music-button {
  position: fixed;
  /* padding: 30px 15px; */
  border: 1px solid $secondary-color;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  top: 89px;
  left: -40px;
  background: #333333;
  cursor: $cursor-pointer;
  z-index: 999;
  i {
    margin: auto;
    position: $position-absolute;
    top: 50%;
    left: 67%;
    transform: translate(-50%, -50%);
    width: 20px;
  }
}
.warning-div .modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}
.googleLogin {
  font-family: Helvetica, sans-serif !important;
  font-weight: 100 !important;
  -webkit-font-smoothing: antialiased !important;
  color: $primary-color !important;
  cursor: $cursor-pointer !important;
  display: inline-block !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  transition: background-color 0.3s, border-color 0.3s !important;
  background-color: #4c69ba !important;
  border: calc(0.06887vw + 0.67769px) solid #4c69ba !important;
  padding: calc(0.34435vw + 13.38843px) calc(0.34435vw + 18.38843px) !important;
  span {
    padding: 5px;
  }
}

.facebookLogin {
  font-family: Helvetica, sans-serif !important;
  font-weight: 500 !important;
  -webkit-font-smoothing: antialiased !important;
  color: $primary-color !important;
  cursor: $cursor-pointer !important;
  display: inline-block !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  transition: background-color 0.3s, border-color 0.3s !important;
  background-color: #4c69ba !important;
  border: calc(0.06887vw + 0.67769px) solid #4c69ba !important;
  padding: calc(0.34435vw + 13.38843px) calc(0.34435vw + 18.38843px) !important;
  span {
    padding: 5px;
  }
}
.Toastify__toast--success {
  background-color: #333333;
  h1 {
    color: #ffffff;
  }
  p {
    color: #ffffff;
    margin-top: 3px;
  }
  .Toastify__close-button {
    color: #ffffff;
    opacity: 1;
  }
}
.Toastify__toast--error {
  background-color: #333333;
  h1 {
    color: #ffffff;
  }
  p {
    color: #ffffff;
    margin-top: 3px;
  }
  .Toastify__close-button {
    color: #ffffff;
    opacity: 1;
  }
}

.fa:hover,
.far:hover {
  color: $secondary-color;
}

.fa.disable:hover,
.far.disable:hover {
  text-decoration: none;
  color: inherit;
}

i:hover {
  color: $secondary-color;
}
i.disable:hover {
  text-decoration: none;
  color: inherit;
}

.mr-8 {
  margin-right: 8px;
}

.playing-background-div {
  box-shadow: 1px 0px 5px 4px rgb(220 186 108 / 47%);
  -webkit-box-shadow: 1px 0px 5px 4px rgb(220 186 108 / 47%);
  -moz-box-shadow: 1px 0px 5px 4px rgb(220 186 108 / 47%);
}

.calc-scroll-main-section {
  height: calc(100% - 180px);
}

@media screen and (max-width: 767px) {
  .calc-scroll-main-section {
    height: calc(100% - 150px);
  }
  ::-webkit-scrollbar {
    width: 6px !important;
  }
  .music-tracks::-webkit-scrollbar {
    width: 5px !important;
  }
}
.fa-2_5x {
  font-size: 2.5em !important;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background-color: #a6a6a6;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #666565;
}

.memberList {
  .pn-member-list {
    max-height: 200px;
    .pn-member {
      padding-left: 0px;
    }
    .pn-member__main {
      display: flex;
      align-items: center;
    }
  }
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
}

.error-message {
  position: absolute;
  font-size: 12px;
  font-weight: normal;
  bottom: -11px;
  width: 100%;
  left: 9px;
}

.shadow_box_cast_header {
  background-color: #222222;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  top: 0px;
  height: 42px;
  width: 42px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: 18px;
    font-weight: 200;
    opacity: 0.8;
  }
}

.music_user_cast {
  background-color: #222222;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  right: 25px;
  top: 16px;
  height: 42px;
  width: 42px;
  text-align: center;
  i {
    font-size: 20px;
    opacity: 0.8;
  }
}
.cast-header-logo {
  position: relative;
  top: 2px;
}

//now Playing popup
.modal-header-stopAll {
  color: $primary-color;
  font-size: 18px;
  margin-bottom: 15px;
}

@media only screen and (max-device-width: 100%) {
  .modal-stopAll {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 7%;
    // width: 100%; /* Full width */
    // height: 100%; /* Full height */
    // margin-left: -500px;
    overflow: auto; /* Enable scroll if needed */
    background-color: $gray-black-color; /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
}
/* Modal Content */
.modal-content-stopAll {
  position: $position-relative;
  background-color: $gray-black-shadow-color;
  margin: auto;
  padding: 15px;
  width: 450px;
  -webkit-box-shadow: 0 4px 8px 0 RGB(0 0 0 / 20%),
    0 6px 20px 0 RGB(0 0 0 / 19%);
  box-shadow: 0 4px 8px 0 RGB(0 0 0 / 20%), 0 6px 20px 0 RGB(0 0 0 / 19%);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  border-radius: 8px;
}

.my-masonry-grid-section {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  grid-column-gap: 20px;
  // width: 100%;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

.my-masonry-grid_column-section {
  width: 550px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .my-masonry-grid_column-section {
    width: 475px;
  }
  .my-masonry-grid-section {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
    -moz-column-gap: 0px;
    column-gap: 0px;
  }
  .my-masonry-grid-section .my-masonry-grid_column-section {
    margin-right: 20px;
  }
  .sidebar-tooltop-div [tooltip]::after {
    min-width: 150px !important;
    left: -50px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1660px) {
  .my-masonry-grid_column-section {
    width: 550px;
  }
}
@media only screen and (min-width: 1661px) and (max-width: 1999px) {
  .my-masonry-grid_column-section {
    width: 560px;
  }
}

[tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: 0.9em; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: "";
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}
[tooltip]::after {
  content: attr(tooltip); /* magic! */

  /* most of the rest of this is opinion */
  font-family: Helvetica, sans-serif;
  text-align: center;

  /* 
    Let the content set the size of the tooltips 
    but this will also keep them from being obnoxious
    */
  display: inline-block;
  // white-space: nowrap;
  overflow: hidden;
  border-radius: 0.3ch;
  background: #be6464;
  color: #fff;
  z-index: 1000; /* absurdity 2 */
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 12px;
  color: #fff;
}
.sidebar-tooltop-div [tooltip]::after {
  min-width: 170px;
  left: -50px;
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip=""]::before,
[tooltip=""]::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #be6464;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 12px;
  color: #fff;
}
// [tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  transform: translate(-50%, -0.5em);
}

@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}

.page-tooltip-div .sidebar-tooltop-div [tooltip]::after {
  min-width: 250px !important;
  left: -45px;
}

.page-tooltip-div [tooltip]:not([flow])::after,
[tooltip][flow^="down"]::after {
  bottom: calc(100% + 5px);
}



.page-tooltip-div-pinned [tooltip]:not([flow])::after,
[tooltip][flow^="down"]::after {
  bottom: -60px !important;
  left: -95px;
}

.last-tooltip [tooltip]:not([flow])::after,
.last-tooltip [tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}

[tooltip][flow^="down"]::after {
  bottom: calc(100% + 5px);
}

.mob-tooltip-div .__react_component_tooltip {
  min-width: 180px !important;
  max-width: 180px;
  left: unset !important;
  text-align: center;
  right: 35px;
}
.mob-tooltip-div .__react_component_tooltip::after {
  display: none !important;
}

.sidebar-tooltop-div .first-tooltip [tooltip]::after {
  bottom: -75px !important;
}

.playlist-first-tooltip [tooltip]::after {
  top: -61px;
}

input,
textarea,
button,
select,
div,
span,
a {
  -webkit-tap-highlight-color: transparent;
  // -webkit-tap-highlight-color: rgba(0,0,0,0);
}


@media only screen and (min-width: 320px) and (max-width: 767px) {
}

@media only screen and (min-width: 320px) and (max-width: 380px) {
  .mob-tooltip-div .sidebar-tooltop-div [tooltip]::after {
    left: -10px;
    top: -75px;
  }

  .mob-btn-state-box-div .sidebar-tooltop-div [tooltip]::after {
    left: 34px !important;
    top: -75px;
  }
  .mob-btn-state-box-div .sidebar-tooltop-div [tooltip]::after {
    min-width: 100px;
    left: 0px;
  }
}

@media only screen and (min-width: 381px) and (max-width: 450px) {
  .mob-tooltip-div .sidebar-tooltop-div [tooltip]::after {
    left: -5px;
    top: -75px;
  }

  .mob-btn-state-box-div .sidebar-tooltop-div [tooltip]::after {
    left: 43px !important;
    top: -75px;
  }
  .mob-btn-state-box-div .sidebar-tooltop-div [tooltip]::after {
    min-width: 120px;
    left: 0px;
  }
}

@media only screen and (min-width: 451px) and (max-width: 767px) {
  .mob-tooltip-div .sidebar-tooltop-div [tooltip]::after {
    left: 0px;
    top: -75px;
  }

  .mob-btn-state-box-div .sidebar-tooltop-div [tooltip]::after {
    left: 58px !important;
    top: -75px;
  }
  .mob-btn-state-box-div .sidebar-tooltop-div [tooltip]::after {
    min-width: 130px;
    left: 0px;
  }
}

.profile-wrapper .menu li:hover {
  background-color: #dcba6c !important;
  a {
    color: #222222 !important;
    font-weight: 400;
  }
}

.arrow_box{
  width: 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .sidebar-tooltop-div [tooltip]::after {
    min-width: 155px !important;
    left: -55px !important;
  }
}


.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
/*   left: -10px; */
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #dcba6c ;
}

input:focus + .slider {
  box-shadow: 0 0 1px #dcba6c ;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

