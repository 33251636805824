@font-face {
  font-family: 'icomoon';
  src: url('./fonts/icomoon.eot');
  src: url('./fonts/icomoon.eot') format('embedded-opentype'),
    url('./fonts/icomoon.ttf') format('truetype'),
    url('./fonts/icomoon.woff') format('woff'),
    url('./fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.icon-play-light-org:before {
  content: "\e97f";
}
.icon-play-regular-org:before {
  content: "\e980";
}
.icon-plus-light-org:before {
  content: "\e981";
}
.icon-plus-regular-org:before {
  content: "\e982";
}
.icon-window-maximize:before {
  content: "\e97d";
}
.icon-window-minimize:before {
  content: "\e97e";
}
.icon-signal-stream-regular:before {
  content: "\e97b";
}
.icon-signal-stream-solid:before {
  content: "\e97c";
}
.icon-xmark-solid:before {
  content: "\e97a";
}
.icon-xmark:before {
  content: "\e979";
}
.icon-bars1:before {
  content: "\e976";
}
.icon-grip-lines:before {
  content: "\e977";
}
.icon-minus:before {
  content: "\e978";
}

.icon-fire-flame-curved:before {
  content: "\e972";
}
.icon-sword:before {
  content: "\e975";
}
.icon-saxophone-fire:before {
  content: "\e973";
}
.icon-candle-holder:before {
  content: "\e971";
}
.icon-sensor-on:before {
  content: "\e974";
}
.icon-campfire:before {
  content: "\e970";
}
.icon-signal:before {
  content: "\e96f";
}
.icon-full-signal:before {
  content: "\e96e";
}
.icon-sort-size-up:before {
  content: "\e96b";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-stop-circle:before {
  content: "\e96a";
}
.icon-multi-user-icon:before {
  content: "\e968";
}
.icon-history-icon:before {
  content: "\e969";
}
.icon-left-arrow:before {
  content: "\e967";
}
.icon-equalizer:before {  /* Horizontal */
  content: "\e992";
}
.icon-equalizer2:before {  /* Vertical */
  content: "\e993";
}
.icon-play-circle-solid:before {
  content: "\e966";
}
.icon-bars:before {
  content: "\e965";
}
.icon-arrows-alt-solid:before {
  content: "\e964";
}
.icon-square-solid:before {
  content: "\e963";
}
.icon-up-original-icon:before {
  content: "\e961";
}
.icon-down-original-icon:before {
  content: "\e962";
}
.icon-list-music-icon:before {
  content: "\e960";
}
.icon-signal-icon:before {
  content: "\e95b";
}
.icon-icon-sword:before {
  content: "\e95f";
}
.icon-fire-solid:before {
  content: "\e95d";
}
.icon-sort-caret-down-solid:before {
  content: "\e95c";
}
.icon-close-original:before {
  content: "\e95c";
}
.icon-loop-icon-original:before {
  content: "\e958";
}
.icon-pin-icon:before {
  content: "\e959";
}
.icon-setting-icon:before {
  content: "\e95a";
}
.icon-signal-icon:before {
  content: "\e95b";
}
.icon-sort-size-down:before {
  content: "\e953";
}
.icon-more-3-svgrepo-com:before {
  content: "\e951";
}
.icon-more-svgrepo-com:before {
  content: "\e952";
}
.icon-volume-svgrepo-com:before {
  content: "\e94e";
}
.icon-volume-mute-svgrepo-com:before {
  content: "\e950";
}
.icon-cloud-download-alt:before {
  content: "\e94d";
}
.icon-ellipsis-h-solid:before {
  content: "\e956";
}
.icon-times-solid:before {
  content: "\e955";
}
.icon-square-regular:before {
  content: "\e954";
}
.icon-mouse-solid:before {
  content: "\e94f";
}
.icon-share-nodes-solid:before {
  content: "\e94c";
}
.icon-eye-solid:before {
  content: "\e94a";
}
.icon-eye-slash:before {
  content: "\e94b";
}
.icon-envelope-regular:before {
  content: "\e948";
}
.icon-paper-plane-regular:before {
  content: "\e942";
}
.icon-paper-plane-solid:before {
  content: "\e944";
}
.icon-spinner-solid-icon:before {
  content: "\e940";
}
.icon-store-solid-icon:before {
  content: "\e943";
}
.icon-calendar-week-solid-icon:before {
  content: "\e93e";
}
.icon-paypal-icon:before {
  content: "\e93d";
}
.icon-arrow-left-solid-icon:before {
  content: "\e937";
}
.icon-arrow-right-solid-icon:before {
  content: "\e938";
}
.icon-times-light-icon:before {
  content: "\e93b";
}
.icon-times-solid-icon:before {
  content: "\e93c";
}
.icon-user-regular-icon:before {
  content: "\e936";
}
.icon-minus-solid-icon:before {
  content: "\e935";
}
.icon-exclamation-circle-solid-icon:before {
  content: "\e932";
}
.icon-music-solid-icon:before {
  content: "\e933";
}
.icon-redo-alt-solid-icon:before {
  content: "\e934";
}
.icon-plus-solid-icon:before {
  content: "\e931";
}
.icon-mouse-pointer-solid-icon:before {
  content: "\e92f";
}
.icon-trash-alt-regular-icon:before {
  content: "\e930";
}
.icon-sparkles-sharp-svgrepo-com:before {
  content: "\e93f";
}
.icon-share-icon:before {
  content: "\e92e";
}
.icon-mouse-pointer-arrow-icon:before {
  content: "\e92d";
}
.icon-music-icon-single-big:before {
  content: "\e92b";
}
.icon-camara-icon:before {
  content: "\e92a";
}
.icon-head-phone-icon:before {
  content: "\e929";
}
.icon-unlock-icon:before {
  content: "\e928";
}
.icon-cart-icon:before {
  content: "\e920";
}
.icon-chevron-down-arrow:before {
  content: "\e923";
}
.icon-chevron-rigth-arrow:before {
  content: "\e924";
}
.icon-lock-icon:before {
  content: "\e925";
}
.icon-music-icon-single:before {
  content: "\e926";
}
.icon-vertical-line-icon:before {
  content: "\e927";
}
.icon-Albums-icon:before {
  content: "\e90f";
}
.icon-discount-icon:before {
  content: "\e910";
}
.icon-down-arrow-icon:before {
  content: "\e911";
}
.icon-Explore-icon:before {
  content: "\e912";
}
.icon-facebook-icon:before {
  content: "\e913";
}
.icon-info-icon:before {
  content: "\e914";
}
.icon-instagram-icon:before {
  content: "\e915";
}
.icon-Mixer-icon:before {
  content: "\e916";
}
.icon-My-Library-icon:before {
  content: "\e917";
}
.icon-play-icon:before {
  content: "\e918";
}
.icon-Podcasts-icon:before {
  content: "\e919";
}
.icon-search-icon:before {
  content: "\e91a";
}
.icon-Soundboard-icon:before {
  content: "\e91b";
}
.icon-twiter-icon:before {
  content: "\e91c";
}
.icon-Upload-Your-Music-icon:before {
  content: "\e91d";
}
.icon-youtube-icon:before {
  content: "\e91e";
}
.icon-volume-off1:before {
  content: "\e906";
}
.icon-volume-up1:before {
  content: "\e90b";
}
.icon-ellipsis-vertical:before {
  content: "\e900";
}
.icon-ellipsis-horizontal:before {
  content: "\e905";
}
.icon-right-arrow-backup-2-svgrepo-com:before {
  content: "\e90d";
}
.icon-left-arrow-backup-2-svgrepo-com:before {
  content: "\e90e";
}
.icon-lock-open-svgrepo-com:before {
  content: "\e90c";
}
.icon-lock-svgrepo-com:before {
  content: "\e90a";
}
.icon-percentage-off-sticker-svgrepo-com:before {
  content: "\e904";
}
.icon-check-alt:before {
  content: "\e941";
}
.icon-home:before {
  content: "\e902";
}
.icon-pencil:before {
  content: "\e957";
}
.icon-search:before {
  content: "\e986";
}
.icon-cog:before {
  content: "\e994";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-play3:before {
  content: "\ea1c";
}
.icon-pause2:before {
  content: "\ea1d";
}
.icon-loop2:before {
  content: "\ea2e";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram1:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-linkedin2:before {
  content: "\eaca";
}
.icon-plus:before {
  content: "\e92c";
}
.icon-person_outline:before {
  content: "\e922";
}
.icon-play:before {
  content: "\e921";
}
.icon-chevron-down:before {
  content: "\e901";
}
.icon-chevron-up:before {
  content: "\e903";
}
.icon-checkmark:before {
  content: "\e91f";
}
.icon-close-solid:before {
  content: "\e907";
}
.icon-close-outline:before {
  content: "\e908";
}
.icon-close:before {
  content: "\e909";
}
.icon-cloud-download:before {
  content: "\e949";
}
.icon-forward:before {
  content: "\e947";
}
.icon-backward:before {
  content: "\e946";
}
.icon-commenting:before {
  content: "\e945";
}
.icon-address-card-o:before {
  content: "\e93a";
}
.icon-credit-card:before {
  content: "\e939";
}