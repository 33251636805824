body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font: normal normal medium Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal {
  height: 100% !important;
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
  overflow: auto;
  background-color: #333;
  background-color: transparent;
  z-index: 999;
  right: 0;
}

.modal-dialog {
  min-height: 100%;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}
/* Modal Content */
.modal-content {
  padding: 30px 20px;
  position: relative;
  background-color: #222;
  margin: auto;
  width: 450px;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.1s;
  animation-name: animatetop;
  animation-duration: 0.1s;
  border-radius: 8px;
}

.flexible-modal {
  position: absolute;
  z-index: 1;
  background: none !important;
  border: none !important;
  width: fit-content !important;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: rgba(55, 55, 55, 0.6);
  top:0;
  left:0;
  right:0;
  bottom:0;
}
.flexible-modal-resizer {
  position:absolute;
  right:0;
  bottom:0;
  cursor:se-resize;
  margin:5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area{
  background: none !important;
  height: 50px;
  position:absolute;
  left:0;
  top:0;
  cursor:move;
  width: 90% !important;
}

.modal-content.fileUpload-modal-content{
  margin: 0 !important;
  background-color: #222 !important;
  width: 100% !important;
  max-width: 700px !important;
}
.flexible-modal {
  height: max-content !important;
  width: max-content !important;
}