@import "../../variable";

.modalSignIn,
.modalSignUp {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
}
/* Modal Content */
.modal-content {
  position: $position-relative;
  background-color: $gray-black-shadow-color;
  padding: 30px 20px;
  max-width: 450px;
  width: 100%;
  -webkit-box-shadow: 0 4px 8px 0 RGB(0 0 0 / 20%),
    0 6px 20px 0 RGB(0 0 0 / 19%);
  box-shadow: 0 4px 8px 0 RGB(0 0 0 / 20%), 0 6px 20px 0 RGB(0 0 0 / 19%);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  border-radius: 8px;
  margin: 0 auto 0 auto;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: $primary-color;
  float: right;
  font-size: 18px;
}

.close:hover,
.close:focus {
  color: $primary-color;
  text-decoration: none;
  cursor: $cursor-pointer;
}

.modal-header {
  color: $primary-color;
  font-size: 18px;
  margin-bottom: 15px;
}

.form-group-auth {
  margin-bottom: 25px;
}
.form-control {
  width: 100%;
  // padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid $gray-black-color;
  box-sizing: border-box;
  background-color: $gray-black-color;
  border-radius: 8px;
  color: $primary-color;
  font-size: 14px;
  height: 40px;
  padding-left: 15px;
  font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .googleLogin,
  .facebookLogin {
    font-size: 12px !important;
    padding: 10px 10px !important;
  }
  .googleLogin span,
  .facebookLogin span {
    text-align: center !important;
    padding: 0px !important;
  }
}

.no-gutters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
          max-height: 325px;
          overflow: auto;
          overflow-x: hidden;
}
.styles_chip-input__3aumB .styles_chip__i-PT7 {
  background-color: #9e854d !important;
  border: #9e854d !important;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  padding: 3px 18px 5px 12px;
  position: relative;
  display: flex !important;
    align-items: center !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    margin-top: 10px;
    word-break: break-word;
  svg {
    position: relative;
    top: 0px;
    fill: #ffffff;
    left: 10px;
    margin: 0px;
  }
}
.styles_chip-input__3aumB .styles_chip__i-PT7:hover {
  background-color: #333333 !important;
}
.styles_chip-input__3aumB {
  outline: 0 !important;
  box-shadow: unset !important;
}
.styles_chip-input__3aumB input:focus {
  outline: 0 !important;
}
.styles_chip-input__3aumB .styles_chip__i-PT7:hover {
  background-color: none;
}

.styles_chip-input__3aumB .styles_chip__i-PT7.styles_show__3kLFl  {
display: initial;
}

.styles_chip-input__3aumB .col{
  padding: 10px 0px 0px 0px;
  width: 100%;
}

.styles_chip-input__3aumB .p-2 .col-auto{
  padding: 0px 0px 12px 0px;
  
}